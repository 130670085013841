import { Box, Fade } from "@mui/material"

import MyLabel from "../../components/myLabel"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import Generating from "./generating"
import Loading from "../../components/loading"
import { getTemplatePost } from "../../services/ayrshare/getTemplatePost"
import plannerBackground from "../../assets/imgs/planner-background.png";
import plannerRobo from "../../assets/imgs/robo.png";
import plannerRobo2 from "../../assets/imgs/robo2.png";
import calendarImg from '../../assets/imgs/calendar.svg';
import MyButton from "../../components/myButton"
import MyImage from "../../components/myImage"
import { AuthContext } from "../../contexts/auth"
import toast from "react-hot-toast"


const RenderPage = ({page, hasSocials, isMax}) =>{
    if (page ==='generating'){
        return <Generating page={page} origin='calendar' />
    }
    else if (page ==='older'){
        return <OlderUser isMax={isMax}/>
    }   
    else{
        return <NewUser hasSocials={hasSocials} isMax={isMax}/>
    }
}

const OlderUser = ({isMax})=>{

    let navigate = useNavigate();

    return (<Box sx={styles.box}>
                        <Box sx={styles.content}>
                            <Box sx={{marginLeft:'40px', marginRight:'40px',
                            '@media (max-width: 600px)': {
                                marginLeft:'0px', marginRight:'0px',
                            }}}>

                                <Box sx={{marginTop:'20px'}}>
                                    <MyLabel sx={styles.title} text={"You've scheduled everything!"} />
                                </Box>
                                <Box sx={{marginTop:"40px"}}>
                                    <MyLabel sx={styles.description}  text={"Go to the calendar to check your posts"} />
                                    <MyLabel sx={styles.description}  text={"or review your settings"} />
                                </Box>
                                <Box sx={{marginTop:"20px"}}>
                                    <MyButton 
                                        sx={{
                                            marginRight:'20px',
                                             backgroundColor:'#e4e4e4', 
                                             color:'#601c7e'
                                        }}
                                        onClick={()=>{isMax?navigate('/planner-add-editorial'):toast.error('You need to have a Max account')}} 
                                        text="go to settings" 
                                        variant="contained" 
                                        size="large" />
                                    <MyButton 
                                        onClick={()=>{navigate('/planner-editorial')}} 
                                        text="go to the calendar" 
                                        variant="contained" 
                                        size="large" />
                                </Box>
                                <Box sx={{marginTop:'60px', display:'flex', flexDirection:'row'}}>
                                    <MyLabel sx={styles.description2}  text={"Do you already have scheduled posts?"} />
                                    <MyLabel onClick={()=>{isMax?navigate('/planner'):toast.error('You need to have a Max account')}}sx={{...styles.description2, marginLeft:'8px', color:'#6fabff', cursor:"pointer", textDecoration:'underline'}}  text={"CLICK HERE"} />
                                    <MyLabel sx={{...styles.description2, marginLeft:'8px'}}  text={"to go to your calendar."} />
                                </Box>
                            </Box>
                        </Box>
                        
                    </Box>)
}

const NewUser = ({hasSocials, isMax})=>{

    let navigate = useNavigate();

    return (<Box sx={styles.box}>
                        <Box sx={styles.content}>
                            <Box sx={{marginLeft:'40px', marginRight:'40px', '@media (max-width: 600px)': {marginLeft:'10px', marginRight:'10px', marginBottom:'0px', padding:'0px'}}}>

                                <Box sx={{display:'flex', flexDirection:'column', marginTop:'40px'}}>
                                    <MyLabel sx={styles.title} text={'Welcome to Autopost!'} />
                                </Box>
                                <Box sx={{marginTop:"40px", '@media (max-width: 600px)': {marginTop:"40px"}}}>
                                    <MyLabel sx={styles.description}  text={!hasSocials ? "Start here to create a month's worth of posts": "Create posts also for your social media:"} />
                                    <MyLabel sx={styles.description}  text={!hasSocials ? "for your social media with AI": "Instagram, Linkedin, and X."} />
                                </Box>
                                <Box sx={{marginTop:"20px"}}>
                                    <MyButton 
                                        onClick={()=>{!isMax?toast.error('You need to have a Max account'): navigate(!hasSocials ? '/planner-editorial': '/planner-add-editorial')}} 
                                        text="start now" 
                                        variant="contained" 
                                        size="large" />
                                </Box>
                                <Box sx={{marginTop:"20px"}}>
                                    <MyImage sx={styles.icon} src={calendarImg} alt={'calendar icon'}  />
                                </Box>
                                <Box sx={{marginTop:'60px', display:'flex', flexDirection:'row', '@media (max-width: 600px)': {display:'none'}}}>
                                    <MyLabel sx={styles.description2}  text={"Do you already have scheduled posts?"} />
                                    <MyLabel onClick={()=>{!isMax?toast.error('You need to have a Max account'): navigate('/planner')}}sx={{...styles.description2, marginLeft:'8px', color:'#6fabff', cursor:"pointer", textDecoration:'underline'}}  text={"CLICK HERE"} />
                                    <MyLabel sx={{...styles.description2, marginLeft:'8px'}}  text={"to go to your calendar."} />
                                </Box>
                                <Box onClick={()=>{!isMax?toast.error('You need to have a Max account'): navigate('/planner')}} sx={{display:'none', '@media (max-width: 600px)': {display:'block', cursor:'pointer'}}}>
                                    <MyLabel sx={styles.description2}  text={"Do you already have scheduled posts?"} />
                                    <MyLabel sx={styles.description2}  text={"Click hereto go to your calendar."} />
                                </Box>
                                <Box sx={{display:'none','@media (max-width: 600px)':{position:'absolute', left:0, bottom:0, display:'flex',}}}>
                                    <MyImage sx={{height:'25vh'}} src={plannerRobo2} alt={'robo icon'}  />
                                </Box>
                            </Box>
                        </Box>
                        
                    </Box>)
}

const PlannerWelcome = ()=>{
    
    let [submit, setSubmit] = useState(false);
    let [page, setPage] = useState('welcome');
    let [hasSocials, setHasSocial] = useState(false);
    const { user } = useContext(AuthContext);
    let [isMax, setIsMax] = useState(user.subscription_name.includes('Max') || user.email==='giovannucci.ristorazione@gmail.com');

    const get = async () =>{
        setSubmit(true)
        let result = await getTemplatePost();
        
        if (result.data.code !== 404 && result.data?.user?.subscription_name ){
            if (result.data.posts_template.length > 0){
                setIsMax(true)    
            }
            else {
                setIsMax(result.data.user.subscription_name.includes('Max'))
            }
        }

        if (result.data.code !== 404 && result.data.post_logs?.filter(item=>item.stage === null || item.stage === 'PROGRESS').length > 0)
        {
            setPage('generating')
        }
        else if (result.data.code !== 404 && result.data?.posts_template.length > 0){
            if (result.data.socials.length > 4){
                setPage('older')
            }
            else {
                setHasSocial(true)
            }

        }
        setSubmit(false)
    }

    useEffect(()=>{
        get()
    },[])
    

    return (
        <Box sx={{
            backgroundImage:`url(${plannerBackground})`, 
            backgroundSize: 'cover',
            width:'100%', 
            display:'flex', 
            justifyContent:'center', 
            alignItems:'center',
            '@media (max-width: 600px)': {
                alignItems:'normal',

            }
        }}>
            
            <Loading render={submit} />
            <Fade in={!submit} timeout={500}>
                <Box sx={{display:'flex', justifyContent:"center"}}>
                    <RenderPage isMax={isMax} page={page} hasSocials={hasSocials} />
                </Box>
            </Fade>
            
        </Box>

    )

}

const styles = {
    box:{
        padding:'20px 0px',
        borderRadius: '10px',
        boxShadow: '0 0 5px 2px #BCBCBC',
        backgroundColor:'#ffffff',
        '@media (max-width: 600px)': {
            padding:`0px`,
            marginTop:'40px',
            width:'85vw',
            height:"75vh"
        }
    },
    content:{ 
        padding:'10px 40px',
        backgroundPosition: 'left center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundImage:`url(${plannerRobo})`,
        textAlign: 'center', 
        
        '@media (max-width: 600px)': {
            backgroundImage:`none`,
            padding:`2px`,
            position:'relative',
            height:"100%",
        },
    },
    
    title:{
        fontSize:'22pt', 
        fontWeight:'600',
        '@media (max-width: 600px)': {
            fontSize:'18pt',
        }
    },
    description:{
        fontSize:'18pt', 
        color:'grey', 
        '@media (max-width: 600px)': {
            fontSize:'12pt',
        }
    },
    description2:{
        fontSize:'15pt', 
        color:'grey', 
        '@media (max-width: 600px)': {
            fontSize:'11pt',
            textDecoration: 'underline',

        }
    },
    panel: { 
        borderRadius:'12px',  
        boxShadow: '0 0 5px 2px #BCBCBC',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '10px 7px 10px 0px rgba(0, 0, 0, 0.36)',
         },
    },
    panelTextArea: {
        height:'auto',
        backgroundColor:'white', 
        borderRadius:'12px', 
        paddingBottom:'1.5rem', 
        position:'relative', 
        zIndex:1, 
        bottom:'1.5rem'
    },
    panelTitle:{
        fontSize:'14pt', 
        fontWeight:'600', 
        position:'relative', 
        top:'0.5rem'
    },
    panelDescription:{
        fontSize:'12pt',
        position:'relative', 
        top:'0.5rem' }
}

export default PlannerWelcome