import axios from "axios";
import backEnd from "../constants/backEnd";
import endpoints from "../constants/endpoints";
import { getCSRF } from "./getCSRF";

export async function postResendEmail(json) {
    let csrf = await getCSRF();
    return axios.post(`${backEnd.url}${endpoints.access.resendConfirmationEmail}`, json,{
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrf?.csrfToken,
        },
    }).then((response) => {
        return { type: "success", data: response.data };
    })
    .catch((error) => {
        return {
            type: "error",
            data: {
                code: error?.status,
                detail: error?.response?.data?.error,
            },
        };
    });
}
