import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth";
import { login } from "../services/auth";
import validEmail from "../utils/validEmail";
import validPassWord from "../utils/validPassWord";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import checkbot from "../utils/checkbot";

const useLoginState = () => {

    let e = {
        email: false,
        password: false
    }

    const navigate = useNavigate();

    const { signIn } = useContext(AuthContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [errors, setErrors] = useState(e);

    const handleLogin = () => {
        if (submit) {
            return;
        }

        
        let isEmailValid = false;
        let isPasswordValid = false;

        if (email !== "" && validEmail(email)) {
            isEmailValid = true;
        } else {
            setErrors({...e, email:true})
            toast.error(<FormattedMessage id="Provide a valid email" />);
        }

        if (password !== "" && validPassWord(password)) {
            isPasswordValid = true;
        } else {
            setErrors({...e, password:true})
            toast.error(<FormattedMessage id="Provide a valid password. It must have at least 8 characters, letters and numbers." />);
        }

        if (isEmailValid && isPasswordValid) {
            setSubmit(true);
            login(email.replace(" ", ""), password)
                .then(async (register) => {
                    if (register.user?.verified) {
                        let state = await signIn(register, remember);
                        if (state === "SUCCESS") {
                            setSubmit(false);
                            checkbot();
                            if( !register.user.has_tested && !register.user.subscription_name.includes('PRO')){ //&& window.innerWidth > 600
                                navigate("/dashboard?try-pro=true");

                            }else{
                                navigate("/dashboard");
                            }
                            
                        }
                    } else {
                        localStorage.setItem("userEmail", email.replace(" ",""));
                        setSubmit(false);
                        navigate("/userNotValited");
                    }
                })
                .catch((error) => {
                    if (error.message === "NetWork request failed") {
                        toast.error(<FormattedMessage id="Connection failed to server" />);
                    } else {
                        toast.error(<FormattedMessage id={error.message} />);
                    }
                    setSubmit(false);
                });
        }
    };

    return {
        email: {
            value: email,
            set: setEmail,
        },
        password: {
            value: password,
            set: setPassword,
        },
        remember: {
            value: remember,
            set: setRemember,
        },
        errors:{
            value: errors,
            set: setErrors
        },
        submit: {
            value: submit,
            set: handleLogin,
        },
    };
};

export default useLoginState;
