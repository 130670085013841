import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';


import MyImage from "../../components/myImage";
import MyLabel from "../../components/myLabel";
import MyButton from './../../components/myButton';
import Loading from "../../components/loading";

import logo from "../../assets/imgs/logo03.png";
import logo1 from "../../assets/imgs/logo01.png";

import { style } from "./styles";
import { postResendEmail } from "../../services/postResendEmail";


function NotValidated() {
    const navigate = useNavigate();
    const userEmail = localStorage.getItem("userEmail");
    const [loading, setLoading] = useState(false);

    const handleResendEmail = async () => {
        if (!userEmail) {
            toast.error("Email não encontrado. Faça login novamente.");
            navigate("/login");
            return;
        }

        setLoading(true);

        const response = await postResendEmail({ email: userEmail })
        if (response.type === 'success'){
            toast.success(
                <div>
                    <MyLabel text="Confirmation sent to" /> <strong>{userEmail}</strong>
                </div>
            );                         
            navigate("/login");
        }
        else {
            toast.error(<MyLabel text={response.data.detail} />);
        }
        setLoading(false); 
    };

    return (
        <Fade in={true} timeout={500}>
            <Stack direction="row" sx={style.stack}>
                <Loading render={loading} />
                <Container sx={style.leftArea}>
                    <Box sx={style.logo1}>
                        <Link href="https://admind.ai/" underline="none">
                            <MyImage src={logo} alt={'Admind logo'} sx={style.logo} />
                        </Link>
                    </Box>
                </Container>
                <Container sx={style.rightArea}>
                    <Box sx={style.logo2}>
                        <Link href="https://admind.ai/" underline="none" >
                            <MyImage src={logo1} alt={'Admind logo'} sx={style.logo} />
                        </Link>
                    </Box>
                    <Box sx={style.titleArea}>
                        <MyLabel component="h1" text="Please confirm your email address" sx={{ ...style.title, textAlign: 'center' }} />
                    </Box>
                    <Box sx={style.loginArea}>
                        <MyButton onClick={() => navigate("/login")} text="Ok" variant="contained" size="large" sx={style.loginButton} />
                        <Box m={2} />
                        <MyButton
                            onClick={handleResendEmail}
                            text="Resend confirmation email" 
                            variant="outlined"
                            size="large"
                            sx={style.loginButton}
                        />
                    </Box>
                    
                </Container>
            </Stack>
        </Fade>
    );
}
export default NotValidated;
