import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import toast from "react-hot-toast";
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

import PaymentPanel from "../../components/paymentPanel";
import MyModal from "../../components/myModal";
import useUserDetailState from "./../../hooks/useUserDetailState";
import usePricingState from "./../../hooks/usePricingState";
import PricingCards from "../../components/pricingCards";

import Loading from "../../components/loading";
import MyLabel from "../../components/myLabel";
import MySelect from '../../components/mySelect';



import { style } from "./styles";


const Pricing = () => {
    const state = useUserDetailState();
    const princingState = usePricingState()

    const [stateModal, setStateModal] = useState({open:false, title:'Basic', planId:process.env.REACT_APP_SUBSCRIPTION_PLAN1_ID, information:null, value:9.00, currency: 'euro'});

    const BodyModal = ({ title, plan_id, information, value, currency }) => {
        return (
            <PaymentPanel
                title={title}
                closeModal={()=>setStateModal({...stateModal, open:false})}
                plan_id={plan_id}
                paypalCancel={()=>console.log('Ainda não implementado')}
                information={information}
                value={value}
                currency={currency}
            />
        );
    };

    
    useEffect(() => {
        if (!princingState.getPrincings.value) {
            princingState.getPrincings.set();
        }
    }, [princingState.getPrincings]);

    useEffect(() => {
        princingState.getPrincings.set();
    }, [princingState.currency.value, princingState.getPrincings]);

    const buttonClick = async (title, planId, information, value) => {
        if (state.subscription.value === title) {
            toast.success(<FormattedMessage id="Your Plan" />);
        } else if (state.inUpdate.value) {
            toast.success(
                <FormattedMessage id="Your plan is updating. Wait a few minutes" />
            );
        } else {
            setStateModal({
                open:true, 
                title:title, 
                planId:planId, 
                information:information,
                value: value,
                currency: princingState.currency.value
            })
        }
    };


    return (
        <Fade in={true} timeout={500}>
            <Stack sx={style.stack}>
                <Box sx={style.centerArea} >
                    <Loading render={princingState.getPrincings.value} />
                    <Box sx={style.header}>
                        <MyLabel component='h1' text='PricingMessage' sx={style.title}  />
                    </Box>
                    <Box sx={style.selectArea}>
                        <Box>
                            <MySelect 
                                sx={style.select}
                                size="small"
                                id='currency'
                                label='currency'
                                options={['EUR', 'USD','BRL']}
                                value={princingState.currency.value}
                                onChange={(e)=>princingState.currency.set(e.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <PricingCards 
                            pricing={princingState.pricing.value} 
                            pricings={princingState.pricings.value} 
                            update={state.inUpdate.value}
                            state={state} buttonClick={buttonClick}
                            currency={princingState.currency.value}
                            stateModal={stateModal}
                        />
                    </Box>
                </Box>
                <MyModal title="pricing" isOpen={stateModal.open} setIsOpen={()=>setStateModal({...stateModal, open:!stateModal.open})}>
                    <BodyModal
                        title={stateModal.title}
                        plan_id={stateModal.planId}
                        information={stateModal.information}
                        value={stateModal.value}
                        currency={stateModal.currency}
                    />
                </MyModal>
            </Stack>
        </Fade>
    );
};
export default Pricing;
